import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import Prescription from './Medicine';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';

const items = ['Item1', 'Item2', 'Item3'];

const MedicineList = () => {
  //const prescription_list=[];
  const [prescription_list,setPrescriptionList]=useState([]);
  var userid=0;
  useEffect(()=>{
    if (localStorage.getItem('user_info')!==null){
      //navigate('/Landing/tab1')
      userid=JSON.parse(localStorage.getItem('patient_id'));
      console.log("userid");
      console.log(userid);
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PATIENT_API;
    const requestData = {
      task: 'getPatientDocumentsList',
      patient_id: userid
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      if (response.data.length > 0){
        const arr=[];
    //onLogin(username);
    console.log(response.data[0])
    response.data[0].forEach(
        obj=>{
          //console.log(response.data[0])
          //const arr=[...prescription_list];
          arr.push(obj['document_date']+','+obj['report_id'])
          //prescription_list.push(obj['document_date']+','+obj['report_id']);
          //prescription_list.push('item')
        }
      );
      setPrescriptionList(arr)
    //localStorage.setItem('user_info', JSON.stringify(response.data[0]));
      }
    })
    .catch(error=>{
      console.log(error)
    });
  },[]);
    //{'task': 'getPatientDocumentsList', 'patient_id': '130'}
    //on click, download html file and display
    return (
        <div>
          <h1>List of Items</h1>
          <ul>
            {prescription_list.map((item, index) => (
              <li key={index}>
                <Link to={`/tab2/${item}`}>{item}</Link>
              </li>
            ))}
          </ul>
          <BottomTab />
          {/* Nested Route for Item Details */}
        </div>
      );
    };


export default MedicineList;

