import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import Prescription from './Medicine';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';

const items = ['Item1', 'Item2', 'Item3'];

const ReportsList = () => {
  //const prescription_list=[];
  const [pathology_list,setPathologyList]=useState([]);
  const [radiology_list,setRadiologyList]=useState([]);

  var userid=0;
  useEffect(()=>{
    if (localStorage.getItem('patient_id')!==null){
      //navigate('/Landing/tab1')
      userid=JSON.parse(localStorage.getItem('patient_id'));
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PATIENT_API;
    const requestData = {
      task: 'getPatientDocumentsList',
      patient_id: userid
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      if (response.data.length > 0){
        const arr=[];
    //onLogin(username);
    //console.log(response.data[0])
    response.data[2].forEach(
        obj=>{
          console.log(response.data[2])
          //{'task': 'getPatientTestDataFromID', 'patient_id': '130', 'test_date': '2023-07-27 19:44:35', 'test_id': '32', 'table_name': 'bloodExamReported'}
          //const arr=[...pathology_list];
          arr.push(obj['table_name']+','+obj['document_date']+','+obj['report_id'])
          //const arr_rad=[...radology_list];
          //arr.push(obj['table_name']+','+obj['document_date']+','+obj['report_id'])
          //setPathologyList(arr)
        }
      );
    //localStorage.setItem('user_info', JSON.stringify(response.data[0]));
    setPathologyList(arr)
      }
    })
    .catch(error=>{
      console.log(error)
    });
  },[]);
    //{'task': 'getPatientDocumentsList', 'patient_id': '130'}
    //on click, download html file and display
    return (
        <div>
          <h1>List of Items</h1>
          <ul>
            {pathology_list.map((item, index) => (
              <li key={index}>
                <Link to={`/tab3/${item}`}>{item}</Link>
              </li>
            ))}
          </ul>
          <BottomTab />
          {/* Nested Route for Item Details */}
        </div>
      );
    };


export default ReportsList;

