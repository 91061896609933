import "../styles/chatStyle.css";
import React, { useState, useEffect, useRef } from "react";
//import { Client as ConversationsClient } from "@twilio/conversations";
import { Client} from "@twilio/conversations";
import axios from 'axios';
import Api from "../Api/Api.json";
import { useParams } from "react-router-dom";
import BottomTab from "./BottomTab";

var userid=0;
var name='';
const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [token,setToken]=useState("");
  const [conversationSID,setConversationSID]=useState("");
  const {patient_id}=useParams();
  //onst identity = prompt("Enter your chat username:");
  const getChatToken = async () => {
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    console.log(patient_id);
    const requestData = {
      task: 'getConversationCredForNonPatient',
      patient_id: patient_id,
      identity:'Health Worker',
      timezone:'Asia/Dhaka',
    };
    console.log('calling page data api');
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
      console.log(JSON.stringify(result.data));
      setToken(result.data.body);
        setConversationSID(result.data.conv_sid);      
    }
    })
};

  useEffect(()=>{ //get token on page load
    getChatToken();
  },[]);

  useEffect(() => { //initialize client on token change

    const initializeChat = async () => {
      try {
        //await getChatToken();
        //const token = await getToken();
        //const token='eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJqdGkiOiJTSzViYjY0NDI3Njk5NGY5YWY4NzVkNmI5Yzc3ZjhhZTMyLTE3MjY4NzAxMjIiLCJncmFudHMiOnsiY2hhdCI6eyJzZXJ2aWNlX3NpZCI6IklTNmU1YjQ3YzExNTBjNGNhZGFjZDg5ZDQwZmNlNjIyZTUifSwiaWRlbnRpdHkiOiJqb2huX2RvZSJ9LCJpc3MiOiJTSzViYjY0NDI3Njk5NGY5YWY4NzVkNmI5Yzc3ZjhhZTMyIiwiZXhwIjoxNzI2ODczNzIyLCJuYmYiOjE3MjY4NzAxMjIsInN1YiI6IkFDMjNlYTNjNDdiNmE0ZGY2NzhmOWRmMWM3MDFhMzcyYTYifQ.5Y8WT4IdyIFDCXJl8Urz-koRnXS-Yyb7oDExE-gPVpU';
        // Initialize Twilio Conversations client
        const client=new Client(token);
        //const client = await Client.create(token);
        // Get or create a conversation (replace with your conversation SID)

        await client.on('initialized', () => {
            console.log('created client');
          });

        client.on('initFailed', ({ error }) => {
            console.log('client init failed');
          });
          const conversation = await client.getConversationBySid(conversationSID);
          /*
          const conversation = await client.createConversation({
            friendlyName: "My New Conversation"  // You can customize this
          });
          console.log("Conversation created successfully:", conversation.sid);
          */

        //const conversation = await client.createConversation();
        //await conversation.add('cedric');

        // Set conversation state
        setConversation(conversation);
        setLoading(false);
        //await conversation.add('john_doe');
        // Fetch initial messages
        const fetchedMessages = await conversation.getMessages();
        setMessages(fetchedMessages.items);

        // Listen for new messages
        conversation.on("messageAdded", (message) => {
          setMessages((prevMessages) => [...prevMessages, message]);
        });
      } catch (err) {
        console.error("Error initializing chat:", err);
        setError("Error initializing chat.");
      }
    };

    if(token){ //if the new token is not null
      initializeChat();
      }
 
  }, [token]);

  const sendMessage = async () => {
    if (messageInput.trim() === "" || !conversation) return;

    try {
      await conversation.sendMessage(messageInput);
      setMessageInput("");
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (loading) return <div>Loading chat...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="chat-container">
      <h2 style={{ color: 'white' }}>Semion Health Chat</h2>
      <div className="messages-container">
        {messages.map((message, index) => (
          <div key={index} className="message">
            <strong>{message.author}</strong>: {message.body}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container">
        <input
          type="text"
          value={messageInput}
          onChange={handleInputChange}
          placeholder="Type your message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
      <BottomTab />
    </div>
  );
};

export default Chat;