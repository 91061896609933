import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe("YOUR_PUBLISHABLE_KEY");

const PaymentSettings = () => {
  const [autoPay, setAutoPay] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission
    console.log("Form submitted");
  };

  return (
    <Elements stripe={stripePromise}>
      <div style={{ maxWidth: "400px", margin: "auto", padding: "20px" }}>
        <h2>Payment Settings</h2>
        <form onSubmit={handleFormSubmit}>
          {/* Line 1: Card Number */}
          <div style={{ marginBottom: "20px" }}>
            <label htmlFor="card-element" style={{ display: "block", marginBottom: "8px" }}>
              Card Number
            </label>
            <CardElement
              id="card-element"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Arial, sans-serif",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>


          {/* Auto-Pay Checkbox */}
          <div style={{ marginBottom: "20px" }}>
            <input
              type="checkbox"
              id="auto-pay"
              checked={autoPay}
              onChange={() => setAutoPay(!autoPay)}
              style={{ marginRight: "8px" }}
            />
            <label htmlFor="auto-pay">Enable Auto Payment</label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              color: "#fff",
              backgroundColor: "#1E90FF",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save Payment Method
          </button>
        </form>
      </div>
    </Elements>
  );
};

export default PaymentSettings;
