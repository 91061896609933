import axios from 'axios';
import Api from "../Api/Api.json";
import React, { useState,useEffect } from 'react';
import BottomTab from './BottomTab';
import { Link, Route,Routes} from 'react-router-dom';

const SubscriptionPlan = () => {

  return (
    <div>
      <h1>Item Details</h1>
      <p>Item Name:</p>
      <ul>
              <li key={1}>
                <Link to={`https://buy.stripe.com/28oaHq4ZX9Tjbo4288`}>plan: Basic</Link>
              </li>
              <li key={2}>
              <Link to={`https://buy.stripe.com/28oaHq4ZX9Tjbo4288`}>plan: Premium</Link>
              </li>
              <li key={3}>
                <Link to={`https://buy.stripe.com/28oaHq4ZX9Tjbo4288`}>plan: Corporate</Link>                                
                </li>            
          </ul>
      <BottomTab />
    </div>

  );
};

export default SubscriptionPlan;
