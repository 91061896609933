import React, { useState } from 'react';
import '../styles/RegistrationPage.css';
import md5Hex from 'md5-hex';
import axios from 'axios';
import Api from "../Api/Api.json";
import { useNavigate } from 'react-router-dom';

const initialFormData = {
  name: '',
  age: '',
  gender: '',
  phone: '',
  address: '',
  email: '',
  referrerCode: '',
  password: '',
  retypePassword: '',
};

const RegistrationPage = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const navigate=useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Submit the form data
      const {name,age,gender,phone,address,email,referrerCode,password,retypePassword}=formData;
      const body = { task: "registerPatient", name, age, gender, email, phone, address, password: md5Hex(password), long: 0, lat: 0, referrer_code: '000', device_id: 'noid' }
      const headers = { headers: { "Content-Type": "application/json" } }
      axios
          .post(Api.PATIENT_API, body, headers)
          .then(res => {
            console.log(res)
            if(res.data===200)
            {
              //reset form data
              setFormData(initialFormData);
              alert('Registered');
              //navigate to login page
              navigate('/')
            }
            else{
              alert("Error! Cannot Signup. Make sure you didn't use this number for another account.")
            }
          });
      console.log('Form submitted:', formData);
    } else {
      setErrors(validationErrors);
    }
  };

  const validateFormData = (data) => {
    const errors = {};
    // Validate mandatory fields
    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!data.age.trim()) {
      errors.age = 'Age is required';
    } else if (isNaN(data.age)) {
      errors.age = 'Age must be a number';
    }
    if (!data.gender.trim()) {
      errors.gender = 'Gender is required';
    }
    if (!data.phone.trim()) {
      errors.phone = 'Phone number is required';
    } else if (!/^\d+$/.test(data.phone)) {
      errors.phone = 'Invalid phone number';
    }
    if (!data.password.trim()) {
      errors.password = 'Password is required';
    }
    if (!data.retypePassword.trim()) {
      errors.retypePassword = 'Retype Password is required';
    } else if (data.retypePassword !== data.password) {
      errors.retypePassword = 'Passwords do not match';
    }
    return errors;
  };

  return (
    <div className="registration-container">
      <h2>User Registration</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name*</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span>{errors.name}</span>}
        </div>
        <div className="form-group">
          <label>Age*</label>
          <input
            type="text"
            name="age"
            value={formData.age}
            onChange={handleChange}
          />
          {errors.age && <span>{errors.age}</span>}
        </div>
        <div className="form-group">
          <label>Gender*</label>
          <select name="gender" value={formData.gender} onChange={handleChange}>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          {errors.gender && <span>{errors.gender}</span>}
        </div>
        <div className="form-group">
          <label>Phone*</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && <span>{errors.phone}</span>}
        </div>
        <div className="form-group">
          <label>Address</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Referrer Code</label>
          <input
            type="text"
            name="referrerCode"
            value={formData.referrerCode}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Password*</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && <span>{errors.password}</span>}
        </div>
        <div className="form-group">
          <label>Retype Password*</label>
          <input
            type="password"
            name="retypePassword"
            value={formData.retypePassword}
            onChange={handleChange}
          />
          {errors.retypePassword && <span>{errors.retypePassword}</span>}
        </div>
        <button type="submit">Submit</button>
      </form>
      <button onClick={() =>navigate('/')}>Back to Login</button>
    </div>
  );
};

export default RegistrationPage;