import React, { useState, useEffect } from 'react';
import ShowGraph from './ShowGraph';
import UpDownSurvey from '../components/UpDownSurvey';
import moment from 'moment';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';
import JsPlugin from '../JsPlugin';
import { Plugins} from '@capacitor/core';
import { Directory, Encoding } from '@capacitor/filesystem';
import TableComponent from '../components/TableComponent';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {Dialog} from '@capacitor/dialog';
const { Filesystem, Storage,Device} = Plugins;

var userid=0;

const ChiefComplaints = () => {
  const [initialized, setInitialized] = useState(false);
  const [hasCC, setHasCC] = useState(false);
  const [enableCheckBtn,setEnableCheckBtn]=useState(false);
  const [ccTableData,setCCTableData]=useState([[]]);
  const [statusText,setText]=useState("'শুরু করুন' বোতামে চাপ দিয়ে সমস্যা বলা শুরু করুন।");
  const [audioPath,setAudioPath]=useState("");

  //setText(local_apk_version);
  useEffect(()=>{

    if(!initialized){
      setInitialized(true); 
      if (localStorage.getItem('user_info')!==null){
        //console.log(JSON.parse(localStorage.getItem('user_info')).di_id)
        userid=JSON.parse(localStorage.getItem('patient_id'));
        //console.log(userid)
      }

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PATIENT_API;
    const requestData = {
      task: 'getChiefComplaintList',
      patient_id: userid,
    };
    console.log(requestData);
    
    axios.post(url,requestData,headers)
    .then(response=>{
      //console.log(response.data)
      if (response.data.length > 0){
        setCCTableData(response.data)
      }
    })
    .catch(error=>{
      console.log(error)
    });
  }//if(!initialized)
  },[initialized]);
  



const handleEdit = async (item) => {
  //const newText = prompt('Edit the Problem Description:', item[1]);
  const { value: newText, cancelled } = await Dialog.prompt({
    title: 'Edit Problem Description',
    message: 'Edit the Problem Description:',
    inputText: item[1],
    okButtonTitle: 'Save',
    cancelButtonTitle: 'Cancel'
});
  if (!cancelled && newText !== null && newText !== item[1]) {
      item[1]=newText;
      if (localStorage.getItem('user_info')!==null){
        //console.log(JSON.parse(localStorage.getItem('user_info')).di_id)
        userid=JSON.parse(localStorage.getItem('user_info')).di_id;
        //console.log(userid)
      }      

      const headers = { headers: { "Content-Type": "application/json" } }
      const url = Api.PATIENT_API;
      const requestData = {
        task: 'editChiefComplaintListItem',
        date: item[0],
        updatedText:item[1],        
        patient_id: userid,
      };
      axios.post(url,requestData,headers)
      .then(response=>{
        //console.log(response.data)
      })
      .catch(error=>{
        console.log(error)
      });
  }
};

  return (

    <div>
<p>{statusText}</p>
        {/*<TableComponent data={ccTableData}/>*/}
        <ul>
          <li><strong>তারিখ       </strong>  -  <strong>সমস্যা</strong></li>
        {ccTableData.map(item => (
          <li>
            <div>
               {item[0]} -  {item[1]} - <button onClick={() => handleEdit(item)}>Edit</button>
            </div>
            {/*
            <button onClick={() => handleDelete(item.id)}>Delete</button>*/}
          </li>
        ))}
      </ul>
        <BottomTab />
     </div>
    //</div>
  );
};

export default ChiefComplaints;
