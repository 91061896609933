import React from 'react';
import { BrowserRouter as Router, Routes, Route,Link,useLocation, Outlet } from 'react-router-dom';
import ShowGraph from './ShowGraph';
import Tab1 from './Tab1';
import BottomTab from './BottomTab';
import '../styles/LandingPage.css'
import SidebarNav from './SidebarNav';
import Dashboard from './Dashboard';
import VideoScriptPage from './VideoScriptPage';
const LandingPage = () => {

  const location = useLocation();

  return (
    <div className="landing-container">
    <div className="content">
      {/* Main content of each tab */}
      {/* This could be Tab1, Tab2, or Tab3 */}
    <Outlet />
    </div>
    <SidebarNav />
    <VideoScriptPage />
    </div>
  );
};

export default LandingPage;

