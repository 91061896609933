import { useParams } from 'react-router-dom';
import axios from 'axios';
import Api from "../Api/Api.json";
import React, { useState,useEffect } from 'react';
import BottomTab from './BottomTab';
import '../styles/PrintReport.css';

const Medicine = () => {
  const { itemName } = useParams();
  const [htmlString,setHtmlString]=useState('');
  var test_date=itemName.split(',')[0]
  var test_id=itemName.split(',')[1]
  var userid=0;
  //var htmlString=''

  useEffect(()=>{
    if (localStorage.getItem('patient_id')!==null){
      //navigate('/Landing/tab1')
      userid=JSON.parse(localStorage.getItem('patient_id'));
      
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PATIENT_API;
    const requestData = {
      task: 'getPrescriptionHtmlString',
      patient_id: userid,
      'test_date': test_date, 
      'test_id': test_id,
      'table_name': 'MEDICINEPRECRIBEDTABLE'
    };
    //console.log(requestData);
    axios.post(url,requestData,headers)
    .then(response=>{
      console.log(response.data)
      if (response.data.length > 0){
    //htmlString+=response.data
    setHtmlString(response.data)
      }
    })
    .catch(error=>{
      console.log(error)
    });
  },[]);

  //{'task': 'getPatientTestDataFromID', 'patient_id': '130', 'test_date': '2023-07-29 00:00:00', 'test_id': '85721', 'table_name': 'MEDICINEPRECRIBEDTABLE'}
  //const htmlString = '<h2>Coming Soon!</h2><p>Content coming Soon!</p>';
  /*
  var htmlString='<b>Medicine:</b><br><table align="center" style="font-family: Tahoma, Arial, Helvetica, sans-serif; float:left; width:100%; height:auto; margin:0; border-collapse: collapse; padding:0;"><tr>'
  htmlString+='<td style="width:10%; border: 1px solid black; color: #000; font-size: 12px; line-height: 1; padding: 0 1%; position: relative; vertical-align: middle;">SL</td>'
  htmlString+='<td style="width:40%; border: 1px solid black; color: #000; font-size: 12px; line-height: 1; padding: 0 1%; position: relative; vertical-align: middle;">Name</td>'
  htmlString+='<td style="width:10%; border: 1px solid black; color: #000; font-size: 12px; line-height: 1; padding: 0 1%; position: relative; vertical-align: middle;">Strength</td>'
  htmlString+='<td style="width:20%; border: 1px solid black; color: #000; font-size: 12px; line-height: 1; padding: 0 1%; position: relative; vertical-align: middle;">Dosage</td>'
  htmlString+='<td style="width:10%; border: 1px solid black; color: #000; font-size: 12px; line-height: 1; padding: 0 1%; position: relative; vertical-align: middle;">Time</td>'
  htmlString+='<td style="width:10%; border: 1px solid black; color: #000; font-size: 12px; line-height: 1; padding: 0 1%; position: relative; vertical-align: middle;">Continue Till</td>'
  htmlString+='</tr></table>';
  */
 
  const renderHTML = (htmlString) => {
    //const styledHtmlString = `<div style="color: white;">${htmlString}</div>`;
    return { __html: htmlString};
    //return { __html: styledHtmlString };
  };

  return (
    <div>
      <h1>Item Details</h1>
      <p>Item Name: {itemName}</p>
      <div className="html-report-container">
      <div dangerouslySetInnerHTML={renderHTML(htmlString)} />
      </div>
      <BottomTab />
    </div>

  );
};

export default Medicine;
