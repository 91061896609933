import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import BottomTab from './BottomTab';
import WellnessLandingCard from '../components/WellnessLandingCard';
import JStyle from '../JStylePlugin';
import axios from 'axios';
import Api from "../Api/Api.json";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryBar,
} from 'victory';
import { formatDate } from '../utility/DataTransformUtils';

const items = ['Sleep', 'HRV', 'HR'];

const hrvData = [
  { day: 1, value: 50 },
  { day: 2, value: 30 },
  { day: 3, value: 30 },
  { day: 4, value: 45 },
  { day: 5, value: 30 },
  { day: 6, value: 25 },
  { day: 7, value: 35 },
  { day: 8, value: 32 },
];

var userid=0;

const handleCardClick = (page) => {
  console.log('card clicked');
};

const WellnessPages = () => {
  const [isConnected,setConnected]=useState(false);
  const [batteryLevel,setBatteryLevel]=useState('NC');
  const [deviceTime,setStateDeviceTime]=useState('NC');
  const [devices, setDevices] = useState([]);
  const [isScanning, setIsScanning] = useState(false);
  const [status,setStatus]=useState('');
  const [pairState,setPairState]=useState("Pair");
  const [hrvList,setHRVList]=useState([{}]); //the hrv list returned from server
  const [sleepDurationList,setSleepDurationList]=useState([{}]); 
  const [sleepStartList,setSleepStartList]=useState([{}]);
  const [sleepEndList,setSleepEndList]=useState([{}]);
  const [sleepScoreList,setSleepScoreList]=useState([{}]);
  const [dayNameList,setDayNameList]=useState([]);
  const [lastServerHRVTS,setLastServerHRVTS]=useState("");
  const [lastServerSleepTS,setLastServerSleepTS]=useState("");
  const [syncStatus,setSyncStatus]=useState("Sync not started.");
  const [deviceName,setDeviceName]=useState('semWear');

    useEffect(()=>{ 
      if (localStorage.getItem('user_info')!==null){
        userid=JSON.parse(localStorage.getItem('user_info')).di_id;
        console.log(userid);
      }

      const headers = { headers: { "Content-Type": "application/json" } }
      const url = Api.FITNESS_API;
      const requestData = {
        task: 'getSemionWellnessData',
        id: userid,
        timezone:'Asia/Dhaka',
      };
      console.log('calling page data api');
      axios.post(url,requestData,headers)
      .then(result=>{
      if(result.data){
        console.log(JSON.stringify(result.data.hrvList));
        //console.log(h_l[1]);
        setHRVList(result.data.hrvList);
        //setLastServerHRVTS(result.data.lasthrvts)
        setSleepDurationList(result.data.sleepDurationList);
        setSleepStartList(result.data.sleepStartList);
        setSleepEndList(result.data.sleepEndList);
        setDayNameList(result.data.dayNameList);
        setSleepScoreList(result.data.sleepScoreList);
        //setLastServerSleepTS(result.data.lastsleepts)
      }  

    });
    console.log("Print from JS");
    //
      JStyle.addListener("connectionResult", handleConnectionEvent); //auto connect event
      JStyle.addListener("disconnectionResult", handleDisconnectionEvent); //auto disconnect event
    //check if a device address is stored
    //check connection
    //check battery level
    var jstyle_address=localStorage.getItem('jstyle_address');
    if (jstyle_address===null){
      setPairState("Pair");
      //prompt scanning
    }
    else{
      //check if device is already connected
      //start connect to device process at address
      setPairState("Unpair");
      
      JStyle.isConnected().then(result=>{
        if(result.connected===true)
        //if(false)
          {
          //fetch data since last fetch
          //last sleep, last HRV, last HR
          setConnected(true);
          syncData();
        }
        else{
          JStyle.connect({'deviceId':jstyle_address});
        }
      }); 
    }

    JStyle.checkPermission().then(result=>{
      console.log(result.permission);
      if(result.permission==='granted'){          
      }
      else{
        setStatus('App Requires Bluetooth Permission');
        JStyle.requestPermission();
        //return;
      }
    });

    return () => {
      JStyle.removeListener("deviceFound");
      JStyle.removeListener("connectionResult");
      JStyle.removeListener("disconnectionResult");
      JStyle.removeListener("hrvDataResult");
      //Flash BT connection (stop scan, disconnect)
      JStyle.stopScan();
      JStyle.disconnect();
    }
    }
    ,[]);

    const pairDevice=()=>{
      if(pairState==='Pair'){
      //start scan
      console.log('pairing');

      JStyle.isScanning().then(result=>{
        setStatus(JSON.stringify(result.scanning));        
        if(!result.scanning===true)
          {
            setStatus('Calling start scan');
            JStyle.addListener("deviceFound", handleDiscoverDevicesEvent);
            JStyle.startScan();
          }
      });
      }
      else if(pairState==='Unpair'){
        console.log('unpairing');
        JStyle.disconnect();
        localStorage.removeItem('jstyle_address');
        setPairState('Pair');
      }
    };

    const forgetDevice=()=>{
      localStorage.removeItem('jstyle_address');
    };

    const connectDevice=(address)=>{
    //connect,set device time    
    JStyle.connect({'deviceId':address});
    //localStorage.setItem('jstyle_address',address);
    setDevices([]);
    };

    const setDeviceTime=()=>{
      const now=new Date();
      const formattedTime=formatDate(now);
      JStyle.setDeviceTime({'date':formattedTime}); //"yyyy.MM.dd HH:mm:ss"
      //JStyle.setDeviceTime({'date':"2024.08.17 00:00:00"}); //"yyyy.MM.dd HH:mm:ss"
    };

    const getDeviceTime=()=>{
    //"yyyy.MM.dd HH:mm:ss"
      JStyle.getDeviceTime().then(result=>{
        setStateDeviceTime(result.devicetime);
      }); 
    };


    const getBatteryLevel=()=>{
      JStyle.getBatteryLevel().then(result=>{
        setBatteryLevel(result.batterylevel);
      });
    };
    const fetchDataAsync=async()=>{
      if (isConnected===false) return;
      //Fetch HRV,sleep,HR since last datetime
      //save last datetime
      setSyncStatus("Sync started");
      console.log('Reading local storage');
      let last_sleep_ts=await localStorage.getItem("last_sleep_datetime");
      let last_hrv_ts=await localStorage.getItem("last_hrv_datetime");
      if (typeof last_sleep_ts === 'undefined'){last_sleep_ts=""}
      if (last_hrv_ts ===null || last_hrv_ts ===undefined){
        console.log('assigning empty str to storage var');
        last_hrv_ts="";
      }
      console.log(last_hrv_ts);
      JStyle.addListener("hrvDataResult",handleHRVData);
      JStyle.getHRVData({'date':last_hrv_ts}).then(result=>{
        //Save HRV data with last saving
        console.log('Reading HRV data');
        if (result.hrvlist){
          console.log(typeof result.hrvlist);
          console.log(JSON.stringify(result.hrvlist[result.hrvlist.length-1]));
        }
      });
    };

    const syncData=()=>{
      if (isConnected===false) return;
      //Fetch HRV,sleep,HR since last datetime
      //save last datetime
      setSyncStatus("Sync started");
      console.log('Reading local storage');
      var last_sleep_ts=localStorage.getItem("last_sleep_datetime");
      var last_hrv_ts=localStorage.getItem("last_hrv_datetime");
      if(last_hrv_ts===undefined || last_hrv_ts===null){last_hrv_ts="";}
      if(last_sleep_ts===undefined || last_sleep_ts===null){last_sleep_ts="";}
      console.log(last_hrv_ts);
      JStyle.getHRVData({'date':last_hrv_ts}).then(result=>{
        //Save HRV data with last saving
        console.log('HRV data read returned');
        localStorage.setItem("last_hrv_datetime",result.lasthrvtime);
        localStorage.setItem("last_hrv_data",result.hrvlist);
        //const hrvList=JSON.parse(result.hrvlist);
        //setStatus(JSON.stringify(result.hrvlist));
        //upload to server     
        console.log(last_sleep_ts);   
        //last_sleep_ts="2024-08-22 22:46:01";
        return JStyle.getSleepData({'date': last_sleep_ts});
      }).then(sleepResult => {
        //Save sleep data with last saving
        console.log('sleep data read returned');
        localStorage.setItem("last_sleep_datetime",sleepResult.lastsleeptime);
        localStorage.setItem("last_sleep_data",sleepResult.sleeplist);
        setSyncStatus("Read from device.");
        //const sleepList=JSON.parse(sleepResult.sleeplist);
        //setStatus(JSON.stringify(sleepResult.sleeplist));
        //save data locally
        
        const hrvDataRead=localStorage.getItem('last_hrv_data');
        //localStorage.setItem("last_sleep_data",null);
        //format [datatype-timestamp-array]
        //upload to server        
        const headers = { headers: { "Content-Type": "application/json" } }
        const url = Api.FITNESS_API;
        const requestData = {
          task: 'setSemionWellnessData',
          id:userid,
          sleep_list:sleepResult.sleeplist,
          hrv_list: hrvDataRead===null? []:hrvDataRead,
        };
        console.log(JSON.stringify(requestData));
        return axios.post(url,requestData,headers);
      }).then(uploadResult=>{
        if(uploadResult.data==='updated'){
          setSyncStatus("Uploaded Successfully.");
        }
        else {
          setSyncStatus("Uploaded.");
        }

      }).catch(error => {
        setStatus(JSON.stringify(error));
      });
      
    };

    const clearDeviceData=()=>{
    
    };
    
    const checkBTPermission=()=>{
      JStyle.checkPermission().then(result=>{
        console.log(result.permission);
      });    
    };

    const requestBTPermission=()=>{
      JStyle.requestPermission().then(result=>{
        console.log(result.permission);
      });    
    };
    


    const handleDiscoverDevicesEvent=(device)=>{
      //setStatus(device.mac);
      //deviceId,name,mac
        //setListenerText(device.deviceUUId)
        setDeviceName(device.name);
        setDevices([...devices, device]);
    };

    const handleConnectionEvent=(deviceInfo)=>{
      setStatus('connected');
      //get battery level, set device time and save device address in storage
      localStorage.setItem('jstyle_address',deviceInfo.address);
      setConnected(true);
      setDeviceTime();
      getBatteryLevel();
      setPairState('Unpair');
      //syncData();
    };

    const handleDisconnectionEvent=(deviceInfo)=>{
      setStatus('disconnected');
      setConnected(false);
    };

    const handleHRVData=(hrvResult)=>{
      console.log('from listener');
      console.log(JSON.stringify(hrvResult.hrvlist));
      if (Array.isArray(hrvResult.hrvlist)){console.log('array');}
      console.log(hrvResult.lasthrvtime);
      //console.log(hrvResult.hrvlist[hrvResult.hrvlist.length-1]);
    };

    return (
        <div>
          {/*
          connected, not connected [pair/unpair based on device connection status]
          Show found devices on clicking pair
          */}
          {/*<h1>Coming soon!</h1>*/}
          <p>{status}</p>
          <p>Device Connected: {JSON.stringify(isConnected)}</p>
          <p>Device: {deviceName}</p>
          <p>Battery Level: {batteryLevel}</p>
          <p>Data Sync: {syncStatus}</p> 
          <button onClick={pairDevice}> {pairState} </button>
          <button onClick={forgetDevice}> Forget Device </button>
          {!isConnected &&
            devices.map((device) => (
              <p key={device.name}>
                <button
                  className="button"
                  onClick={() => connectDevice(device.mac)}
                >
                  Connect to {device.name}
                </button>
              </p>
            ))}

          <button onClick={syncData}>Sync Data</button>

          <p>HRV Today: {hrvList[6]?.y ?? 'Data not available'}</p>
          <p>Sleep Duration Today: {sleepDurationList[6]?.y ?? 'Data not available'}</p>


          <VictoryChart
      domain={{ y: [20, 70] }}
      height={400}
      padding={{ top: 20, bottom: 60, left: 60, right: 60 }}
    >
      <defs>
        <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#1FD2DC" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#1FD2DC" stopOpacity="0.2" />
        </linearGradient>
      </defs>

      <VictoryAxis
        tickValues={[1,2,3,4,5,6,7]}//
        tickFormat={dayNameList}//['Sat','Sun','Mon','Tue','Wed','Thu','Fri']}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'none' },
        }}
      />
      
      <VictoryAxis
        dependentAxis
        tickValues={[20, 40, 60, 80, 100]}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'grey', strokeWidth: 0.5 },
        }}
      />

      <VictoryBar
        data={hrvList}
        size={4}
        style={{
          data: { fill: '#1FD2DC' },
        }}
      />
        <VictoryLine
        data={hrvList}
        interpolation="natural"
        size={4}
        style={{ data: { stroke: "#c43a31", strokeWidth: 7, strokeLinecap: "round" } }}
      />
      </VictoryChart>

      <VictoryChart
      domain={{ y: [60, 100] }}
      height={400}
      padding={{ top: 20, bottom: 60, left: 60, right: 60 }}
    >
      <defs>
        <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#1FD2DC" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#1FD2DC" stopOpacity="0.2" />
        </linearGradient>
      </defs>

      <VictoryAxis
        tickValues={[1,2,3,4,5,6,7]}//
        tickFormat={dayNameList}//['Sat','Sun','Mon','Tue','Wed','Thu','Fri']}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'none' },
        }}
      />
      
      <VictoryAxis
        dependentAxis
        tickValues={[70, 80, 90, 100]}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'grey', strokeWidth: 0.5 },
        }}
      />

      <VictoryBar
        data={sleepScoreList}
        size={4}
        style={{
          data: { fill: '#1FD2DC' },
        }}
      />
        <VictoryLine
        data={sleepScoreList}
        interpolation="natural"
        size={4}
        style={{ data: { stroke: "#c43a31", strokeWidth: 7, strokeLinecap: "round" } }}
      />
      </VictoryChart>

          {/*
          <ul>
              <li key='1'><Link to={`/tab3/${items[0]}`}>{items[0]}</Link></li>
              <li key='2'><Link to={`/tab3/${items[1]}`}>{items[1]}</Link></li>
              <li key='3'><Link to={`/tab3/${items[2]}`}>{items[2]}</Link></li>                            
          </ul>*/}
          <BottomTab />
        </div>
      );
    };


export default WellnessPages;