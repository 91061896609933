import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';



var userid=0;

const DoctorReviewNotes = () => {
  const [initialized, setInitialized] = useState(false);
  const [commentsList,setCommentsList]=useState([]);
  const [patientId,setPatientId]=useState(null);

  useEffect(()=>{
    userid=localStorage.getItem('patient_id');
    setPatientId(userid);
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    const requestData = {
      task: 'getPatientNotes',
      patient_id:userid,
      doctor_review_note:true,
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      //console.log(response.data)
      if(response.data.length>0)
        {
        setCommentsList(response.data);
        }
    })
    .catch(error=>{
      console.log(error)
    });

  },[]);
  
const submitComment=()=>{
  const comment = document.getElementById('input_comment').value;
    // Check if the comment is empty
    if (!comment) {
      alert('Please enter a comment');
      return;
    }

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    const requestData = {
      task: 'createPatientNotes',
      patient_id:patientId,
      doctor_review_note:comment,
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      //console.log(response.data)
    })
    .catch(error=>{
      console.log(error)
    });

};


  return (
    <div>
    <p>Review Notes:</p>

      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {commentsList.map((item) => (
            <tr key={item.id}>
              <td>{item.timestamp}</td>
              <td>{item.note}</td>
            </tr>
          ))}
        </tbody>
      </table>      
          <input id='input_comment' type='text' />
          <button onClick={() => submitComment()}>Submit</button>
        <BottomTab />
     </div>
    //</div>
  );
};

export default DoctorReviewNotes;