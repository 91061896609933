import React, { useState } from 'react';
import '../styles/UpDownStyle.css'
import moment from 'moment';
import axios from 'axios';
import Api from "../Api/Api.json";
import AudioPlugin from '../AudioPlugin';
import { SpeechRecognition } from "@capacitor-community/speech-recognition";
import JsPlugin from '../JsPlugin';

const RecordAudio = ({onChildEvent}) => {
  const dailyEmo = useState(null);
  const [text,setText]=useState("False");
  const [listeningStatus,setListeningStatus]=useState("Not listening");
  const [isListening,setListening]=useState(false);

  const checkSpeechRec=()=>{
    SpeechRecognition.available().then(result=>{
        //console.log(result.available);
        if (result!==null){
        setText(result.available);
        }
    });
  };
  const permissionGranted=()=>{
    SpeechRecognition.requestPermission().then(result=>{
        //console.log(result.available);
        if (result!==null){
        setText(result);
        }
    });
  };

  const startTranscribeSpeech=()=>{
    //send request to capacitor to start recording
    console.log('start');
    /*
    AudioPlugin.printValue({ value: 'Hello from JavaScript' }).then(result => {
        console.log(result.message); // Log the result from the plugin
        //setText(result.message);
      });*/
      setListening(true);
      setListeningStatus("Listening");

      SpeechRecognition.start({
        language: "bn-IN", //en-US
        maxResults: 2,
        prompt: "Say something",
        partialResults: true, 
        popup: false,
      })/*.then(result=>{
        if (result!==null){
            setText(result.matches);
            if(isListening===true){
              setListeningStatus("Continnue Listening");
              //startRecordAudio()
            }
            else{
              setListeningStatus("Stopped Listening");
            }
            }
      });*/
      
      SpeechRecognition.addListener("partialResults", (data: any) => {
        setText(data.matches);
      });
  };

  const startRecordAudio=()=>{
      //JsPlugin.readValue({ value: 'Hello from JavaScript' }).then(result => {
        JsPlugin.startRecording({ value: 'Hello from JavaScript' }).then(result => {
          console.log(result.message); // Log the result from the plugin
          setText(result.message);
        });
  };

  const stopRecordAudio=()=>{
    //send request to capacitor to stop recording
    setListening(false);
          JsPlugin.stopRecording({ value: 'Hello from JavaScript' }).then(result => {
              console.log(result.message); // Log the result from the plugin
              setText(result.message);
            });
        /*    
        SpeechRecognition.stop().then(result=>{
          setText("stopped");
        });
        */
  };
  const playAudio=()=>{
  JsPlugin.playRecording({value:'play audio'}).then(result=>{
    setText(result.message);
  });
  };
  const uploadAudio=()=>{
    onChildEvent();
    var userid=0;
    if (localStorage.getItem('user_info')!==null){
      //console.log(JSON.parse(localStorage.getItem('user_info')).di_id)
      userid=JSON.parse(localStorage.getItem('user_info')).di_id;
      //console.log(userid)
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PATIENT_API;
    const requestData = {
      task: 'uploadAudio',
      patient_id: userid,
      insert_day:moment().format("YYYY-MM-DD")
    };
    console.log(requestData);
  };

  return (
    //<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div>
    <p>{listeningStatus}</p>
    <p>{text}</p>
    <button onClick={() =>checkSpeechRec()}> Check Recognizer</button>
    <button onClick={() =>permissionGranted()}> Check Permission</button>
    <button onClick={() =>startRecordAudio()}> Start</button>
    <button onClick={() =>stopRecordAudio()}> Stop</button>
    <button onClick={() =>playAudio()}> Play</button>
    <button onClick={() =>uploadAudio()}> Upload</button>

    </div>
    //</div>
  );
};

export default RecordAudio;
