import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';
import TableComponent from '../components/TableComponent';
import {Dialog} from '@capacitor/dialog';


var userid=0;
let audio = null;

const EditSpeechList = () => {
  const [ccTableData,setCCTableData]=useState([{}]);
  const [playing,setPlaying]=useState(false);
  const [audioPath,setAudioPath]=useState("");
  const [patientId,setPatientId]=useState(null);

  //setText(local_apk_version);
  useEffect(()=>{

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    const requestData = {
      task: 'getNonEditedSpeechCCList'
    };

    axios.post(url,requestData,headers)
    .then(response=>{
      console.log(response.data)
      if (response.data.length > 0){
        setCCTableData(response.data)
      }
    })
    .catch(error=>{
      console.log(error)
    });

  },[]);
  


const togglePlay = async (item) => {

      const url=await getPresignedUrlData(item);
      //console.log(url);
      //console.log(item.patient_id);

    
    if(!audio || audioPath!==url){
      console.log('instantiating audio')
      audio = new Audio(url);
      setAudioPath(url);
    }
    await audio.play();

    /*
    setPlaying(!playing);
    if (!playing) {
     await audio.play();
    } else {
      audio.pause();
    }*/
  };

async function getPresignedUrlData(item) {
  // Implementation depends on your backend
  const headers = { headers: { "Content-Type": "application/json" } }
  const url = Api.TELEDOC_PROVIDER_API;
  const requestData = {
    task: 'getHealthlogSpeechPresignedURL',
    "patient_id": item.patient_id,
    "date": item.date,
  };
  //console.log(requestData);
  const response = await axios.post(url,requestData,headers)
  return response.data;
}

const handleEdit = async (item) => {
  //const newText = prompt('Edit the Problem Description:', item[1]);
  const { value: newText, cancelled } = await Dialog.prompt({
    title: 'Edit Problem Description',
    message: 'Edit the Problem Description:',
    inputText: item.cc,
    okButtonTitle: 'Save',
    cancelButtonTitle: 'Cancel'
});

  if (!cancelled && newText !== null && newText !== item.cc) {
      item.cc=newText;
      userid=item.patient_id;
 
      const headers = { headers: { "Content-Type": "application/json" } }
      const url = Api.PATIENT_API;
      const requestData = {
        task: 'editChiefComplaintListItem',
        date: item.date,
        updatedText:item.cc,        
        patient_id: userid,
        edit_flag:true,
      };
      axios.post(url,requestData,headers)
      .then(response=>{
        //console.log(response.data)
      })
      .catch(error=>{
        console.log(error)
      });
  }
};

  return (
    //1. Check if there is any active cc
    //2. One check-in allowed per day
    //3.  onChildEvent={handleRatingButtonEvent}
    //<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

    <div>

    <p>Edit Speech Health Log</p>

    <table>
        <thead>
          <tr>
            <th>Patient ID</th>
            <th>Date</th>
            <th>CC</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ccTableData.map((item) => (
            <tr key={item.patient_id}>
              <td>{item.patient_id}</td>
              <td>{item.date}</td>
              <td>{item.cc}</td>
              <td>
                {/* Play button */}
                <button onClick={() => togglePlay(item)}>Play</button>
                {/* Edit button */}
                <button onClick={() => handleEdit(item)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>      
        <BottomTab />
     </div>
    //</div>
  );
};

export default EditSpeechList;
