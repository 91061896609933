import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import BottomTab from './BottomTab';
import {
    VictoryChart,
    VictoryLine,
    VictoryAxis,
    VictoryLabel,
    VictoryLegend,
    VictoryTheme,
  } from "victory";
import axios from 'axios';
import Api from "../Api/Api.json";


var userid=0;


const WellnessDataFineGrained = () => {
    const [chartData, setChartData] = useState({
        hrv: [],
        heartRate: [],
        bloodPressure: [],
        stress: [],
      });

  useEffect(()=>{ 
    if (localStorage.getItem('patient_id')!==null){
      //navigate('/Landing/tab1')
      userid=JSON.parse(localStorage.getItem('patient_id'));
      console.log(userid);
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.FITNESS_API;
    const requestData = {
      task: 'getWellnessDataFineGrained',
      patient_id: 130//userid
    };
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
      console.log(result.data);
      const hrvData = result.data.map((item, index) => ({
        x: index + 1, // Use index as x-axis value
        y: item.hrv,
      }));
      const heartRateData = result.data.map((item, index) => ({
        x: index + 1,
        y: item.heart_rate,
      }));
      const systolicData = result.data.map((item, index) => ({
        x: index + 1,
        y: item.systolic,
      }));
      const diastolicData = result.data.map((item, index) => ({
        x: index + 1,
        y: item.diastolic,
      }));
      const stressData = result.data.map((item, index) => ({
        x: index + 1,
        y: item.stress,
      }));

      setChartData({
        hrv: hrvData,
        heartRate: heartRateData,
        bloodPressure: { systolic: systolicData, diastolic: diastolicData },
        stress: stressData,
      });

      }
    });
  }    
  ,[]);



    return (
        <div>
          <h1>Wellness Data Finegrained!</h1>
          <h2>HRV</h2>
      <VictoryChart theme={VictoryTheme.material}>
        <VictoryAxis label="Index" />
        <VictoryAxis dependentAxis label="HRV" />
        <VictoryLine
          data={chartData.hrv}
          style={{ data: { stroke: "#4bc0c0" } }}
        />
      </VictoryChart>

      <h2>Heart Rate</h2>
      <VictoryChart theme={VictoryTheme.material}>
        <VictoryAxis label="Index" />
        <VictoryAxis dependentAxis label="Heart Rate" />
        <VictoryLine
          data={chartData.heartRate}
          style={{ data: { stroke: "#ff6384" } }}
        />
      </VictoryChart>

      <h2>Blood Pressure (Systolic & Diastolic)</h2>
      <VictoryChart theme={VictoryTheme.material}>
        <VictoryAxis label="Index" />
        <VictoryAxis dependentAxis label="Blood Pressure" />
        <VictoryLegend
          x={125}
          y={10}
          orientation="horizontal"
          gutter={20}
          data={[
            { name: "Systolic", symbol: { fill: "#36a2eb" } },
            { name: "Diastolic", symbol: { fill: "#ffce56" } },
          ]}
        />
        <VictoryLine
          data={chartData.bloodPressure.systolic}
          style={{ data: { stroke: "#36a2eb" } }}
        />
        <VictoryLine
          data={chartData.bloodPressure.diastolic}
          style={{ data: { stroke: "#ffce56" } }}
        />
      </VictoryChart>

      <h2>Stress</h2>
      <VictoryChart theme={VictoryTheme.material}>
        <VictoryAxis label="Index" />
        <VictoryAxis dependentAxis label="Stress" />
        <VictoryLine
          data={chartData.stress}
          style={{ data: { stroke: "#9966ff" } }}
        />
      </VictoryChart>

          <BottomTab />
        </div>
      );
    };


export default WellnessDataFineGrained;