// Tab1.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomTab from './BottomTab';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { App } from '@capacitor/app';
import {Plugins} from '@capacitor/core';
import axios from 'axios';
import Api from "../Api/Api.json";
import JsPlugin from '../JsPlugin';
//const { App, Browser,Filesystem } = Plugins;
//const{ FilesystemDirectoryPath } = Filesystem;

const Logout = () => {
  const [statusText,setText]=useState("apk");
  const [verText,setVerText]=useState("1.0.0");  
    const navigate=useNavigate();
    const logout=()=>{
        localStorage.removeItem('user_info');
        navigate('/')
    }
    const testAPK=()=>{
    checkRemoteAPK();
    }
    const checkRemoteAPK = async () => {
      const { version, versionCode } = await App.getInfo();
      setVerText(version+'.'+versionCode)
      var local_apk_version='1.0.6'
      
      const headers = { headers: { "Content-Type": "application/json" } }
      const url = Api.PATIENT_API;
      console.log(local_apk_version)
      const requestData = {
        task: 'checkAPKUpdate',
        client_version_string: local_apk_version,
      };
      axios.post(url,requestData,headers)
      .then(response=>{
        console.log(response.data)
          if (response.data.version==='latest'){
              console.log('apk updated');
          }
          else{
              console.log('installing apk');
              handleInstallApk(response.data);
          }
      })
      .catch(error=>{
        console.log(error)
      });
  };

  const handleInstallApk = async (resp) => {
    try {
        JsPlugin.updateApk({ url: resp.url,version:resp.version }).then(result => {
            setText("installing");
        });

        //localStorage.setItem('apk_version', JSON.stringify(resp.version));
        console.log('APK installed successfully');
      } catch (error) {
        console.error('Error downloading and installing APK:', error);
      }
  };
  const startChat=()=>{
    navigate('/chat');
  };

  return (
    <div>
      <h2>Logout</h2>
      <button onClick={startChat}>Chat</button>
      <br></br>
      <button onClick={logout}>Logout</button>
      <br></br>
      <button onClick={testAPK}>Check Apk</button>


      {/*<p>{statusText}</p>
  <p>{verText}</p>*/}
      <BottomTab />
    </div>
  );
};

export default Logout;

// Tab2.js and Tab3.js similar to Tab1.js
