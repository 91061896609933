import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShowGraph from './ShowGraph';
import UpDownSurvey from '../components/UpDownSurvey';
import moment from 'moment';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';
import JsPlugin from '../JsPlugin';
import { Plugins} from '@capacitor/core';
import { Directory, Encoding } from '@capacitor/filesystem';
import TableComponent from '../components/TableComponent';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {Dialog} from '@capacitor/dialog';
const { Filesystem, Storage,Device} = Plugins;

var userid=0;

const AnswerChatList = () => {
  const [initialized, setInitialized] = useState(false);
  const [hasCC, setHasCC] = useState(false);
  const [ccTableData,setCCTableData]=useState([{}]);
  const [audioPath,setAudioPath]=useState("");
  const [playing,setPlaying]=useState(false);
  const [text,setText]=useState("");
  const navigate=useNavigate();
  //setText(local_apk_version);
  useEffect(()=>{

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    const requestData = {
      task: 'getLastPatientRepliedRoomList'
    };

    axios.post(url,requestData,headers)
    .then(response=>{
      console.log(response.data)
      if (response.data.length > 0){
        setCCTableData(response.data)
      }
    })
    .catch(error=>{
      console.log(error)
    });

  },[]);
  
 const goToChat=(item)=>{
    const patient_id=item[1].split("_")[1]
    const navpath='/chat/'+patient_id
    console.log(navpath);
    navigate(navpath);
 };

  return (
    <div>
    <p>Answer Patient Chat</p>
        <ul>
          <li><strong>তারিখ       </strong>  -  <strong>সমস্যা</strong></li>
        {ccTableData.map(item => (
          <li>
            <div>
               {item[2]} - <button onClick={() => goToChat(item)}>Chat</button>
            </div>
          </li>
        ))}
      </ul>
        <BottomTab />
     </div>
    //</div>
  );
};

export default AnswerChatList;