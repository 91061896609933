import React,{useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,Link,useLocation, Outlet,useNavigate } from 'react-router-dom';
import Tab1 from './Tab1';
import BottomTab from './BottomTab';
import '../styles/LandingPage.css'
import axios from 'axios';
import Api from "../Api/Api.json";


const Dashboard = () => {
  const navigate=useNavigate();
   return (
    <div>
      <h1>User List:</h1>
      <ul>
        <h4>Individual Patient Tasks:</h4>
        <li key='1'><Link to={`/landing/rpm_list`}>RPM List</Link></li>
          <li key='2'><Link to={`/landing/pregnancy_list`}>Pregnancy Monitoring Patients List</Link></li>
          <li key='3'><Link to={`/landing/semion_list`}>Semion Patients List</Link></li>
          <h4>Group Tasks:</h4>
          <li key='4'><Link to={`/landing/edit_speech_list`}> Edit Speech CC</Link></li>
          <li key='5'><Link to={`/landing/answer_chat_list`}> Answer Patient Chat </Link></li>
          <li key='6'><Link to={`/landing/idle_patient_list`}> Check Idle Patients </Link></li>
          <h4>Misc Tasks:</h4>
          <li key='7'><Link to={`/landing/text_to_speech`}> Text to Speech </Link></li>
      </ul>
    </div>
  );
};

export default Dashboard;

