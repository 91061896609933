import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import BottomTab from './BottomTab';
import axios from 'axios';
import Api from "../Api/Api.json";



var patient_id='0';
let audio = null;
const Stetho = () => {
    const [mp3FileList,setMP3FileList]=useState([]);    
    const [mp3URL,setMP3URL]=useState('');
    const [imageURL,setImageURL]=useState('');
    const [playing,setPlaying]=useState(false);

    useEffect(()=>{
      if (localStorage.getItem('user_info')!==null){
        //navigate('/Landing/tab1')
        patient_id=JSON.parse(localStorage.getItem('patient_id'));
      }
      const headers = { headers: { "Content-Type": "application/json" } }
      const url = Api.PATIENT_API;
      const requestData = {
        task: 'getUSBStethoMP3List',
        patient_id: patient_id
      };
      axios.post(url,requestData,headers)
      .then(response=>{
        if (response.data.length > 0){
        setMP3FileList(response.data);             
        }
      })
      .catch(error=>{
        console.log(error)
      });
    },[]);

    const handleClick = async (item) => {
      //get image url
      //get mp3 url
      const headers = { headers: { "Content-Type": "application/json" } }
      const url = Api.PATIENT_API;
      var requestData = {
        task: 'getStethoDownloadURL',
        patient_id: patient_id,
        fileName:item
      };
      await axios.post(url,requestData,headers)
      .then(response=>{
        if (response.data.length > 0){
        setMP3URL(response.data);   
        }
      })
      .catch(error=>{
        console.log(error)
      });
      requestData = {
        task: 'getStethoWaveImg',
        patient_id: patient_id,
        fileName:item
      };
      await axios.post(url,requestData,headers)
      .then(response=>{
        if (response.data.length > 0){
        setImageURL(response.data);   
        }
      })
      .catch(error=>{
        console.log(error)
      });

    };

    const togglePlay = async () => {
      if (!audio) {
        audio = new Audio(mp3URL);
      }
      setPlaying(!playing);
      if (!playing) {
       await audio.play();
      } else {
        audio.pause();
      }
    };

    return (
        <div>
          {imageURL !== "" && (
        <div>
          <h3>Stetho Image:</h3>
          <img src={imageURL} alt="Loaded from URL" />
        </div>
      )}
          {mp3URL !== "" && (
        <div>
          <h3>Play Stetho Audio:</h3>
          <button onClick={togglePlay}>Play</button>
        </div>
      )}
      
      <ul>
        {mp3FileList.map((item) => (
          <li
            key={item}
            style={{ cursor: 'pointer', marginBottom: '10px', listStyle: 'none' }}
            onClick={() => handleClick(item)}
          >
            {item}
          </li>
        ))}
      </ul>
          <BottomTab />
        </div>
      );
    };


export default Stetho;