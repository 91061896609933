import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import 'chart.js/auto' for Chart.js version 3
import moment from 'moment';

const Graph = ({graphData}) => {
  const chartRef = useRef(null);
  const chartInstanceRef=useRef(null);

  useEffect(() => {
    console.log(graphData[0].cc);
    const data = [];
    const labels = [];
    graphData[0].rating.forEach(
      obj => {
        const key = Object.keys(obj)[0]; // Get the first (and only) key
        const value = obj[key];
        const date = new Date(key).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        labels.push(date);
        data.push(value);
      }
    );

      /*
    //const datal=data;
    const today = moment();
    for (let i = 0; i < 14; i++) {
      labels.push(today.subtract(1, 'day').format('MMM D'));
      //data.push(Math.floor(Math.random() * 100)); // Random data for demonstration
    }*/

    const ctx = chartRef.current.getContext('2d');
   if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current=new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels.reverse(),
        datasets: [{
          label: graphData[0].cc,
          data: data.reverse(),
          borderColor: 'blue',
          borderWidth: 1,
          fill: false,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
          },
          y: {
            display: true,
          },
        },
      },
    });
        // Cleanup function to destroy chart instance when component unmounts
        return () => {
            if (chartInstanceRef.current) {
              chartInstanceRef.current.destroy();
            }
          };
  }, []);

  return <canvas ref={chartRef} />;
};

export default Graph;
