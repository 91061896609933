import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import BottomTab from './BottomTab';


import axios from 'axios';
import Api from "../Api/Api.json";


const items = ['BP', 'Stetho', 'Glucose'];

var userid=0;


const SemionScreen = () => {



    return (
<div>
      <h1>Semion Screen Data:</h1>
      <ul>
              <li key='1'><Link to={`/tab2/SemionScreen/${items[0]}`}>{items[0]}</Link></li>
              <li key='2'><Link to={`/tab2/SemionScreen/${items[1]}`}>{items[1]}</Link></li>
              <li key='3'><Link to={`/tab2/SemionScreen/${items[2]}`}>{items[2]}</Link></li>
          </ul>

      <BottomTab />
    </div>
          
      );
    };


export default SemionScreen;