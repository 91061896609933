import React from 'react';
import '../styles/FrontPage.css';

function FrontPage() {
  return (
    <div className="front-page">
      {/* Header */}
      <header className="header">
        <div className="logo">MyStory: Tell Your Story With Your Video</div>
        <nav className="nav">
          <a href="#features">Features</a>
          <a href="#pricing">Pricing</a>
          <a href="#faq">FAQ</a>
          <a href="#login" className="login-btn">Login</a>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Transform Your Ideas Into Stunning Videos</h1>
          <p>Leverage AI to create professional-grade videos in minutes. No prior experience required!</p>
          <div className="hero-buttons">
            <button className="primary-btn">Get Started</button>
            <button className="secondary-btn">Learn More</button>
          </div>
        </div>
        <div className="hero-image">
          <img
            src="https://via.placeholder.com/600x400"
            alt="Video creation preview"
          />
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <h2>Why MyStory?</h2>
        <div className="features-grid">
          <div className="feature">
            <img src="https://via.placeholder.com/100" alt="Easy to Use" />
            <h3>Easy to Use</h3>
            <p>Create videos effortlessly with our intuitive interface.</p>
          </div>
          <div className="feature">
            <img src="https://via.placeholder.com/100" alt="Customizable Templates" />
            <h3>Customizable Templates</h3>
            <p>Choose from a variety of templates for any occasion.</p>
          </div>
          <div className="feature">
            <img src="https://via.placeholder.com/100" alt="Fast Rendering" />
            <h3>Fast Rendering</h3>
            <p>Generate high-quality videos in a matter of minutes.</p>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta">
        <h2>Ready to Create Your First Video?</h2>
        <button className="primary-btn">Start Now</button>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 AI Video Generator. All Rights Reserved.</p>
        <div className="social-links">
          <a href="#">Facebook</a>
          <a href="#">Twitter</a>
          <a href="#">LinkedIn</a>
        </div>
      </footer>
    </div>
  );
}

export default FrontPage;
