import React, { useState,useEffect } from 'react';
import { Link, Navigate, Route,Routes,useNavigate} from 'react-router-dom';
import BottomTab from './BottomTab';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryBar,
} from 'victory';
import axios from 'axios';
import Api from "../Api/Api.json";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const items = ['BP', 'Stetho', 'Glucose'];
var userid=0;
const tickValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const tickValuesY = [1, 2, 3, 4, 5, 6, 7, 8];

const yaxis_label=['Sat','Sun','Mon','Tue','Wed','Thu','Fri'];
const sleep_array=[['Sat',3,11],['Sun',true,null],['Mon',true,true],['Tue',3,11],['Wed',3,11],['Thu',3,11],['Fri',3,11]];
const test_data=[
  {x: 1, y: null, date: '2024-08-25'},
  {x: 2, y: null, date: '2024-08-26'},
  {x: 3, y: 41, date: '2024-08-27'},
  {x: 4, y: 41, date: '2024-08-28'},
  {x: 5, y: 41, date: '2024-08-29'},  
  {x: 6, y: null, date: '2024-08-30'},
  {x: 7, y: null, date: '2024-08-31'},
];

const WellnessData = () => {
  const [hrvList,setHRVList]=useState([{}]); //the hrv list returned from server
  const [sleepDurationList,setSleepDurationList]=useState([{}]); 
  const [sleepScoreList,setSleepScoreList]=useState([{}]);
  const [sleepStartList,setSleepStartList]=useState([{}]);
  const [sleepEndList,setSleepEndList]=useState([{}]);
  const [dayNameList,setDayNameList]=useState([]);
  const navigate=useNavigate();

  useEffect(()=>{ 
    if (localStorage.getItem('patient_id')!==null){

      userid=JSON.parse(localStorage.getItem('patient_id'));
      console.log(userid);
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.FITNESS_API;
    const requestData = {
      task: 'getSemionWellnessData',
      id: userid,
      timezone:'Asia/Dhaka',
    };
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
      console.log(result.data.hrvList);
      setHRVList(result.data.hrvList);
      setDayNameList(result.data.dayNameList);
      setSleepScoreList(result.data.sleepScoreList);
      }
    });
  }    
  ,[]);

  const toFinegrainedData=()=>{
    navigate('/tab2/WellnessData/WellnessDataFineGrained')
  };

    return (
        <div>
          <h1>Wellness Data!</h1>
          <ul>{/*
              <li key='1'><Link to={`/tab2/vitals/${items[0]}`}>{items[0]}</Link></li>
              
              <li key='2'><Link to={`/tab2/vitals/${items[1]}`}>{items[1]}</Link></li>
              <li key='3'><Link to={`/tab2/vitals/${items[2]}`}>{items[2]}</Link></li>                            
              */}
          </ul>
            <button onClick={toFinegrainedData}>Finegrained Data</button>
            
          <p>HRV Today: {hrvList[6]?.y ?? 'Data not available'}</p>
          <p>Sleep Score Today: {sleepScoreList[6]?.y ?? 'Data not available'}</p>

          <VictoryChart
      domain={{ y: [20, 70] }}
      height={400}
      padding={{ top: 20, bottom: 60, left: 60, right: 60 }}
    >
      <defs>
        <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#1FD2DC" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#1FD2DC" stopOpacity="0.2" />
        </linearGradient>
      </defs>

      <VictoryAxis
        tickValues={[1,2,3,4,5,6,7]}
        tickFormat={dayNameList}//['Sat','Sun','Mon','Tue','Wed','Thu','Fri']}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'none' },
        }}
      />
      
      <VictoryAxis
        dependentAxis
        tickValues={[20, 30, 40, 50, 60, 70]}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'grey', strokeWidth: 0.5 },
        }}
      />
      {/*
          <VictoryArea
        data={test_data}//{hrvList}
        interpolation="natural"
        x='x'
        y='y'
        style={{
          data: {
            fill:'url(#gradientFill)',
            stroke: '#1FD2DC',
            strokeWidth: 2,
          },
        }}
      />
      */}
      <VictoryBar
        data={hrvList}
        size={4}
        style={{
          data: { fill: '#1FD2DC' },
        }}
      />
        <VictoryLine
        data={hrvList}
        interpolation="natural"
        size={4}
        style={{ data: { stroke: "#c43a31", strokeWidth: 7, strokeLinecap: "round" } }}
      />
      </VictoryChart>

        <h4>Sleep Score:</h4>
      <VictoryChart
      domain={{ y: [50, 100] }}
      height={400}
      padding={{ top: 20, bottom: 60, left: 60, right: 60 }}
    >
      <defs>
        <linearGradient id="gradientFill" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#1FD2DC" stopOpacity="0.8" />
          <stop offset="100%" stopColor="#1FD2DC" stopOpacity="0.2" />
        </linearGradient>
      </defs>

      <VictoryAxis
        tickValues={[1,2,3,4,5,6,7]}//
        tickFormat={dayNameList}//['Sat','Sun','Mon','Tue','Wed','Thu','Fri']}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
          grid: { stroke: 'none' },
        }}
        />
  
        <VictoryAxis
          dependentAxis
          tickValues={[60,70, 80, 90, 100]}
          style={{
            axis: { stroke: 'white' },
            tickLabels: { fill: 'white', fontSize: 12, padding: 5 },
            grid: { stroke: 'grey', strokeWidth: 0.5 },
          }}
          />

          <VictoryBar
            data={sleepScoreList}
            size={4}
            style={{
              data: { fill: '#1FD2DC' },
            }}
          />
            <VictoryLine
            data={sleepScoreList}
            interpolation="natural"
        size={4}
        style={{ data: { stroke: "#c43a31", strokeWidth: 7, strokeLinecap: "round" } }}
      />
      </VictoryChart>

            {/*

          <VictoryChart 
          width={4000}
          height={1600}
          padding={{ top: 0, bottom: 100, left: 200, right: 20 }}
          domain={{ x: [0, 13], y: [0, 8] }}>

      <VictoryAxis
        scale="linear" // Use linear scale for numerical values
        tickValues={tickValues}
        tickFormat={(t) => {
          if (t === 5) {
            return '12';
          } else if (t > 5) {
            return `${t - 5}`;
          } else if (t < 5) {
            return `${t + 7}`;
          } else {
            return `${t}`;
          }
        }}
        style={{ tickLabels: { fontSize: 100, fill: 'white' } }}
      />

      <VictoryAxis
        dependentAxis
        tickValues={[1, 2, 3, 4, 5, 6, 7, 8]}
        tickFormat={yaxis_label}
        style={{ tickLabels: { fontSize: 100, fill: 'white' } }}
      />
          {sleep_array.map((day, index) => (
        <VictoryLine
          key={index}
          data={[
            { x: day[1], y: index+1 }, //tickValuesY[index]
            { x: day[2], y: index+1 }, //tickValuesY[index]
          ]}
          style={{ data: { stroke: 'white', strokeWidth: 40 } }} // Adjust stroke color and width as needed
        />
      ))}
          </VictoryChart>
          */}
          <BottomTab />
        </div>
      );
    };


export default WellnessData;