// App.js
import React, { useState,lazy } from 'react';
import {Link} from 'react-router-dom'
import LoginPage from './LoginPage';
import LandingPage from './LandingPage';
import ShowGraph from './ShowGraph';
import '../styles/LandingPage.css'
//import { lazyLoad } from './lazyLoad';

//https://www.youtube.com/watch?v=JU6sl_yyZqs
//const LoginPage=lazyLoad('./pages/LoginPage.jsx','Login')
//const LandingPage=lazy(()=>import('./pages/LandingPage.jsx'))

const BottomTab = () => {
      return (
        <div className='bottom-navigation'>
            <nav>
          <ul>
            <li>
              <Link to="/landing/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/landing/tab2">Subscription</Link>
            </li>
            <li>
              <Link to="/landing/tab3">Payment Settings</Link>
            </li>
            <li>
              <Link to="/landing/tab4">Menu</Link>
            </li>
            {/*<li>
              <Link to="/landing/tab5">Record Audio</Link>
            </li>*/}
          </ul>
          </nav>
        </div>
      );
    };
export default BottomTab;
