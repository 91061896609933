import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import BottomTab from './BottomTab';
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTheme,  VictoryBar,VictoryScatter } from 'victory';

import axios from 'axios';
import Api from "../Api/Api.json";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const items = ['BP', 'Stetho', 'Glucose'];

var userid=0;


const Vitals = () => {
  const [data, setData] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(()=>{ 
    if (localStorage.getItem('patient_phone')!==null){
      //navigate('/Landing/tab1')
      userid=JSON.parse(localStorage.getItem('patient_phone'));
      console.log(userid);
    }

    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.FITNESS_API;
    const requestData = {
      task:"getVitals",
      patient_id:userid,
    };
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
      console.log(result.data);
      setData(result.data);
      }
    });
  }    
  ,[]);

  // Handle the button click to show the selected field in a graph
  const handleFieldClick = (field) => {
    setSelectedField(field);

    // Prepare data for the Victory chart
    const chartData = data
    .filter((entry) => entry[field] !== 0 && typeof entry[field] === 'number')  // Filter out zero or non-numeric values
    .map((entry) => ({
      x: new Date(entry.measurement_time), // Use measurement_time for x-axis
      y: entry[field],  // Use the selected field for y-axis
    }));

    setChartData(chartData);
  };

    return (
<div>
      <h1>Vital Signs Dashboard</h1>
      
      {/* Show the chart when a field is selected */}
      {selectedField && (
        <div style={{ width: '80%', margin: '50px auto' }}>
          <h3>Graph for {selectedField}</h3>

          <VictoryChart
            theme={VictoryTheme.material}
            scale={{ x: 'time' }} // X-axis is time-based
          >
                <VictoryAxis
    tickFormat={(t) => `${t.getDate()}/${t.getMonth() + 1}`} // Format ticks as day/month
  />
            <VictoryAxis
              dependentAxis
              label={selectedField} // Y-axis label is the selected field
            />
        
        <VictoryBar
        data={chartData}
        size={4}
        style={{
          data: { fill: '#1FD2DC' },
        }}
      />
            <VictoryLine
              data={chartData} // Data for the line chart
              style={{
                data: { stroke: '#c43a31' },
                parent: { border: '1px solid #ccc' },
              }}
            />
          </VictoryChart>
        </div>
      )}

      {/* Display buttons for each field in one row */}
      {data.length > 0 && (
        <div>
          {Object.keys(data[0])
            .filter((key) => key !== 'measurement_time') // Filter out the time field
            .map((field) => (
              <div key={field}>
              <button key={field} onClick={() => handleFieldClick(field)}>
                {field}
              </button>
              </div>
            ))}
        </div>
      )}


      <BottomTab />
    </div>
          
      );
    };


export default Vitals;