import React, { useState } from 'react';

const VideoScriptPage = () => {
  const [description, setDescription] = useState('');
  const [generatedScript, setGeneratedScript] = useState('');
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleGenerateScript = async () => {
    //call api and return script.
    const id_token = await localStorage.getItem('id_token');
    const headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };
      const base_url = "https://pmco1162s7.execute-api.us-east-1.amazonaws.com/prod";
      const body = {
        task: "getScriptFromDescription",
        description: description
      };
      try {
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }  
        const data = await response.json();
        setGeneratedScript(data);
      } catch (err) {
        console.log(err.message);
      }
    const script = `This is a script based on the topic: "${description}". Please refine it further.`;
    setGeneratedScript(script);
    setIsScriptGenerated(true);
  };

  const handleSubmitScript = async() => {
    //call api and show message on successful submission.
    const id_token = await localStorage.getItem('id_token');
    const headers = {
        Authorization: `Bearer ${id_token}`,
        "Content-Type": "application/json",
      };

      const base_url = "https://pmco1162s7.execute-api.us-east-1.amazonaws.com/prod";
      const body = {
        task: "submitScriptForVideoGeneration",
        script: generatedScript
      };

      try {
        const response = await fetch(base_url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setGeneratedScript(data);
      } catch (err) {
        console.log(err.message);
      }
    alert(`Your script has been submitted. You'll receive an email with download link when the video is ready.`);
  };

  const handleScriptChange = (e) => {
    setGeneratedScript(e.target.value);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h2>Create Video Script</h2>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="description" style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
          Topic or Video Description:
        </label>
        <input
          type="text"
          id="description"
          placeholder="Enter a topic or description..."
          value={description}
          onChange={handleDescriptionChange}
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
          }}
        />
      </div>
      <button
        onClick={handleGenerateScript}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Generate Script
      </button>

      {isScriptGenerated && (
        <div style={{ marginTop: '20px' }}>
          <h3>Generated Script</h3>
          <textarea
            value={generatedScript}
            onChange={handleScriptChange}
            rows="10"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              resize: 'vertical',
            }}
          />
          <button
            onClick={handleSubmitScript}
            style={{
              marginTop: '10px',
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Submit Script for Video Creation
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoScriptPage;
