import React,{useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,Link,useLocation, Outlet,useNavigate } from 'react-router-dom';
import Tab1 from './Tab1';
import BottomTab from './BottomTab';
import '../styles/LandingPage.css'
import axios from 'axios';
import Api from "../Api/Api.json";

const mockApiData = [
    { id: 1, name: 'John Doe', age: 25, phone: '123-456-7890' },
    { id: 2, name: 'Jane Smith', age: 30, phone: '234-567-8901' },
    { id: 3, name: 'Sam Wilson', age: 22, phone: '345-678-9012' },
    // Add more rows as needed
  ];

const RPMList = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate=useNavigate();
  useEffect(() => {
    // You can replace mockApiData with an actual API call
    // fetch('/api/endpoint').then(response => response.json()).then(setData);
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.TELEDOC_PROVIDER_API;
    const requestData = {
      task: 'getRPMPatientList',
    };
    console.log('calling page data api');
    axios.post(url,requestData,headers)
    .then(result=>{
    if(result.data){
    setData(result.data);
    console.log(JSON.stringify(result.data));
    }
    })

    //setData(mockApiData);
  }, []);

  const handleClick = async (item) => {
    console.log(item.patient_id); 
    await localStorage.setItem("patient_id",JSON.stringify(item.patient_id));
    await localStorage.setItem("patient_phone",JSON.stringify(item.phone));
    navigate(`/landing/tab2`)
   };

  return (
    <div className="landing-container">
    <div className="content">
      {/* Main content of each tab */}
      {/* This could be Tab1, Tab2, or Tab3 */}
      <p>Dashboard</p>
      <h2>List of Semion Patients:</h2>
      <ul>
        {data.map((item) => (
          <li
            key={item.id}
            style={{ cursor: 'pointer', marginBottom: '10px', listStyle: 'none' }}
            onClick={() => handleClick(item)}
          >
            {item.name} | {item.age} | {item.phone} | {item.prescription_date}
          </li>
        ))}
      </ul>

    </div>

    <BottomTab />
    </div>
  );
};

export default RPMList;
