import React, { useState,useEffect } from 'react';
import { Link, Route,Routes} from 'react-router-dom';
import Prescription from './Medicine';
import axios from 'axios';
import Api from "../Api/Api.json";
import BottomTab from './BottomTab';

const items = ['Item1', 'Item2', 'Item3'];
let audio = null;
const ComplaintsList = () => {
  //show list of complaints, speech_cc by date
  //const cc_list=[];
  const [cc_list,setCCList]=useState([]);
  const [speech_cc_list,setSpeechCCList]=useState([]);
  const [speech_cc_obj_list,setSpeechCCObjList]=useState([]);
  const [audioPath,setAudioPath]=useState("");

  var userid=0;
  useEffect(()=>{
    if (localStorage.getItem('user_info')!==null){
      //navigate('/Landing/tab1')
      userid=JSON.parse(localStorage.getItem('patient_id'));
      console.log("userid");
      console.log(userid);
    }
    const headers = { headers: { "Content-Type": "application/json" } }
    const url = Api.PATIENT_API;
    const requestData = {
      task: 'getPatientPrescriptionComplaintsList',
      patient_id: userid
    };
    axios.post(url,requestData,headers)
    .then(response=>{
      if (response.data.length > 0){
        const arr=[];
        const speech_arr=[];

    //console.log(response.data[0])

    response.data[0].forEach(
        obj=>{
          arr.push(obj[0]+','+obj[1])
        }
      );
      setCCList(arr)

      response.data[1].forEach(
        obj=>{
          console.log(obj)
          speech_arr.push(obj[0]+','+obj[1])
          
        }
      );
      setSpeechCCList(speech_arr)
      setSpeechCCObjList(response.data[1])

      //localStorage.setItem('user_info', JSON.stringify(response.data[0]));
      }
    })
    .catch(error=>{
      console.log(error)
    });
  },[]);
    //{'task': 'getPatientDocumentsList', 'patient_id': '130'}
    //on click, download html file and display


const togglePlay = async (item) => {

  const url=await getPresignedUrlData(item);
  console.log(url);
  //console.log(item.patient_id);


if(!audio || audioPath!==url){
  console.log('instantiating audio')
  audio = new Audio(url);
  setAudioPath(url);
}
await audio.play();

/*
setPlaying(!playing);
if (!playing) {
 await audio.play();
} else {
  audio.pause();
}*/
};

async function getPresignedUrlData(item) {
// Implementation depends on your backend
userid=await JSON.parse(localStorage.getItem('patient_id'));
const headers = { headers: { "Content-Type": "application/json" } }
const url = Api.TELEDOC_PROVIDER_API;
const requestData = {
task: 'getHealthlogSpeechPresignedURL',
"patient_id": userid,
"date": item[0],
};
console.log(requestData);
const response = await axios.post(url,requestData,headers)
return response.data;
}


    return (
        <div>
          <h4>Prescription CC Items</h4>
          <ul>
            {cc_list.map((item, index) => (
              <p>{item}</p>
            ))}
          </ul>
          <h4>Speech CC Items</h4>

    <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>CC</th>
            <th>Play</th>
          </tr>
        </thead>
        <tbody>
          {speech_cc_obj_list.map((item) => (
            <tr key={item[0]}>
              <td>{item[0]}</td>
              <td>{item[1]}</td>
              <td>
                {/* Play button */}
                <button onClick={() => togglePlay(item)}>Play</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>      

          <BottomTab />
          {/* Nested Route for Item Details */}
        </div>
      );
    };


export default ComplaintsList;

