// SidebarNavigation.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/SidebarNav.css';

const SidebarNav = () => {
  return (
    <div className="sidebar-navigation">
      <nav>
        <ul>
          <li>
            <Link to="/landing/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/landing/tab2">Subscription</Link>
          </li>
          <li>
            <Link to="/landing/tab3">Payment Settings</Link>
          </li>
          <li>
            <Link to="/landing/tab4">Menu</Link>
          </li>
          {/* <li>
            <Link to="/landing/tab5">Record Audio</Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default SidebarNav;
